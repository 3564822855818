.invite {
    position: relative;
    min-height: 200px;
}

.koolbeach-logo {
    background-color: #000000;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
}

.koolbeach-logo > img {
    max-height: 80px;
}

p {
    font-size: 18px;
    font-weight: bold;
}

.no-comments {
    margin-left: 20px;
}

.players {
    margin-top: 40px;
}

.players > p {
    font-size: 14px;
    font-weight: normal;
}

.skill {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #8a8a8a;
    margin-left: 8px;
}

.messageCt {
    max-width: 400px;
}

.content {
    margin-top: 15px !important;
    margin-left: 20px !important;
}

.notfound {
    padding: 40px;
    margin-top: 60px;
    text-align: center;
    color: #ededed;
    text-shadow: #0f0f10 1px 1px 1px;
}

.player-ct {
    min-width: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    position: relative;
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 12px;
}

.name-ct {
    text-align: center;
}

.playing-y {
    background-color: #1eac42;
    color: #fff;
}

.playing-n {
    background-color: #d21b20;
    color: #fff;
}

.player-count {
    margin-bottom: 8px;
    text-align: center;
    font-size: 12px;
    padding: 2px;
    background-color: #f5f5f5;
}

.players-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
}

.viewed {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #e1e1e1;
}

