.main-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
}

.logout-item {
    text-align: center !important;
}

.logout {
    color: #d20000;
    font-weight: 900;
}