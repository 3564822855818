.logo {
    text-align: center;
    padding: 10px 0;
    color: #fff;
}

.logo-img {
    padding: 0 40px;
}

.admin-label {
    font-size: 16px;
    border-top: 1px dotted #6c87bc;
    padding: 6px 0;
}

.header-title {
    margin: 20px 0;
}
