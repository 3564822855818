.register {
    margin-top: 20px;
}

.success {
    text-align: center;
}

.submit-btn {
    margin-top: 20px;
}

.koolbeach-logo {
    background-color: #000000;
    width: 100%;
    border-radius: 5px;
}

.koolbeach-logo > img {
    max-height: 80px;
}

h2 {
    color: #000000 !important;
}
